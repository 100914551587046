<template>
   <HeaderDash />
   <SideMenu /> 
   <ContentTimeSheet /> 
</template>
<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/team/dashboard/HeaderDash.vue'
import SideMenu from '@/components/team/dashboard/SideMenu.vue'
import ContentTimeSheet from '@/components/team/dashboard/modules/timesheet/ContentTimeSheet.vue'

export default 
{
  name: 'TimeSheet',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentTimeSheet
  }
}
</script>
<style>


</style>